import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import HelmetComponent from '../components/helmetcomponent';
import Img from 'gatsby-image';

export default function Template({ data }) {
  const {
    headerImage,
    servicesText,
    services,
    description,
    title
  } = data.allContentfulPageServices.edges[0].node;

  let servicesDiv = '';
  for (let i = 0; i < servicesText.length; i++) {
    servicesDiv += `<div class="col-xs-12 col-md-4">
		<div class="card">
			<img class="card-img-top" src=${services[i].file.url} alt="" />
			<div class="card-body">
				<p class="card-text">${servicesText[i]}</p>
			</div>
		</div>
	</div>`;
  }

  return (
    <Layout>
      <HelmetComponent title={title} description={description} image={headerImage.fluid.src} />
      <section id="sponsor-hero" className="hero">
        <div id="page-sub-header">
          {/* first <Img> is the purple overlay */}
          <Img
            fluid={headerImage.fluid}
            style={{ position: 'absolute' }}
            className="w-100 bg-img-overlay"
          />
          <Img
            fluid={headerImage.fluid}
            style={{ position: 'absolute' }}
            className="w-100 bg-img"
          />
          <div className="container">
            <div className="page-sub-header-content">
              <div className="row">
                <div className="col-md-7 col-xs-12">
                  <h1>{title}</h1>
                  <p>{description.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="primary" className="content-area">
        <main id="main" className="site-main mt-20" role="main">
          <section id="section-services" className="two-columns">
            <div className="container">
              <div className="row" dangerouslySetInnerHTML={{ __html: servicesDiv }}></div>
            </div>
          </section>
        </main>
      </section>
    </Layout>
  );
}

export const servicesPageQuery = graphql`
  query ServicesPage($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        description
      }
    }
    allContentfulPageServices {
      edges {
        node {
          headerImage {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
          servicesText
          services {
            file {
              url
            }
          }
          description {
            description
          }
          title
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
